<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New </button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
    
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination>
        <template #userType-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('userType', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>
            <option value="1"> Doctor </option>
            <option value="2">Patient</option>
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
       <template #userType="{item}">
        <td
          v-if="item.userType == 1">
            Doctor  </td>
          <td v-else>No </td>
       
        
      </template>  
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText=""
                  color="secondary"
                >
                  <CDropdownItem :to="{name: 'JobType', params: {jobId: item.jobId, mode: 'view'}}">View </CDropdownItem>
           
               
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteFaq(item.jobId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "job Title",  _style: "min-width:200px" },
  
  { key: "created Date",label: "Created Date & Time", _style: "min-width:200px" },
   { key: "show_details",
    label: "Actions",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
//import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "JobTypes",
//   components: {
//     ConfirmationModal,
//   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/faqs")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    deleteFaq(groupId) {
      console.log("delete called with", this.groupId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/web/secure/delete/department/" +
         groupId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "Sub Group", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>